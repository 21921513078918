<template>
  <div
    class="p-4 flex justify-center"
    v-if="!loading && dadosDashboard == undefined"
  >
    <div
      role="alert"
      class="alert alert-error grid-flow-col w-auto h-auto gap-2 md:gap-4"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="stroke-current shrink-0 h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <span
        ><span class="font-bold">Error!</span> Não foi possivel carregar os
        dados</span
      >
    </div>
  </div>
  <div
    class="w-screen h-screen p-4 flex justify-center"
    v-if="loading && dadosDashboard == undefined"
  >
    <span className="loading loading-spinner text-info w-28"></span>
  </div>
  <div class="w-screen p-4" v-if="!loading && dadosDashboard != undefined">
    <div>
      <h1 class="text-3xl font-semibold text-center">Nível Tanque</h1>
    </div>
    <div class="p-4">
      <div>
        <div class="flex flex-col items-center space-y-4">
          <div class="flex flex-col items-center">
            <div class="cilindro">
              <div class="base"></div>
            </div>
            <div class="cilindro cilindro1">
              <div class="base">
                <h1 class="text-center text-6xl -mt-1">
                  {{ dadosDashboard.percentualAtual.toFixed(0) }}%
                </h1>
              </div>
            </div>
            <div class="cilindro cilindro2">
              <div class="base"></div>
            </div>
            <div class="text-center">
              <p class="text-xl font-semibold">
                Nível de Água: {{ dadosDashboard.percentualAtual }}%
              </p>
              <p class="text-sm">
                Última Medição:
                {{ formatDate(dadosDashboard.dataUltimaMedicao) }}
              </p>
              <p class="text-sm">
                Último Abastecimento:
                {{ formatDate(dadosDashboard.dataUltimoAbastecimento) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";

interface DadosDashboard {
  dataUltimaMedicao: Date;
  dataUltimoAbastecimento: Date;
  percentualAtual: number;
}

export default defineComponent({
  name: "HomeNivelTanque",
  setup() {
    const dadosDashboard = ref<DadosDashboard>();
    const loading = ref<boolean>(true);

    function ObterApiKey() {
      let apikey: string | null = window.localStorage.getItem("apikey");
      if (apikey == null || apikey == "") {
        apikey = window.prompt("Digite a chave de acesso:");
        window.localStorage.setItem("apikey", apikey?.toString() || "");
      }
      return apikey;
    }

    const fetchData = async () => {
      try {
        var apikey = await ObterApiKey();
        const url = process.env.VUE_APP_ROOT_API + "Dashboard/";
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": apikey || "",
          },
        });
        if (!response.ok) {
          throw new Error(`Erro HTTP! status: ${response.status}`);
        }
        dadosDashboard.value = await response.json();
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    const formatDate = (date: Date): string => {
      return new Date(date).toLocaleString("pt-BR");
    };

    return {
      dadosDashboard,
      loading,
      formatDate,
    };
  },
});
</script>

<style scoped>
.cilindro {
  width: 17rem;
  height: 2rem;
  background-color: #3b82f6; /* Azul Tailwind */
  border-radius: 0.25rem 0.25rem 0.5rem 0.5rem;
  overflow: hidden;
}
.cilindro1 {
  height: 4rem;
  width: 15rem;
  margin-top: -10px;
  border-radius: 0.25rem 0.25rem 2rem 2rem;
}
.cilindro2 {
  width: 12rem;
  height: 3rem;
  margin-top: -10px;
  border-radius: 0.25rem 0.25rem 2rem 2rem;
}
.base {
  background-color: #1e40af; /* Azul escuro Tailwind */
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
</style>
