<template>
  <HomeNivelTanque />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HomeNivelTanque from "@/components/HomeNivelTanque.vue";

@Options({
  components: {
    HomeNivelTanque,
  },
})
export default class HomeView extends Vue {}
</script>
